.services{
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 90vh;
    margin-bottom: 8rem;
    margin-top: 9rem;
    margin-bottom: 13rem;
}

.awesome{
    display: flex;
    flex-direction: column;
    position: relative;
}

.awesome>:nth-child(1){
    color: var(--black);
    font-size: 2.5rem;
    font-weight: bold;
}

.awesome>:nth-child(2){
    color: var(--orange);
    font-size: 2.3rem;
    font-weight: bold;
}

.awesome>:nth-child(3){
    color: var(--gray);
    font-size: 14px;
    margin-top: 1rem;
}

.s-button{
    width: 8rem;
    height: 1.3rem;
    margin-top: 1rem;
}

.cards{
    position: relative;
}

.cards>*{
    position: absolute;
}

@media  screen and (max-width: 480px){
    .services{
        margin-top: 2rem;
        flex-direction: column;
        gap: 3rem;
        height: 70rem;
        padding: 0rem;
    }
    .cards{
        display: flex;
        flex-direction: column;
        gap: 18rem;
    }
    .cards>*{
        position: static;
    }
}