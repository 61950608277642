.intro{
    display: flex;
    height: 77vh;
    margin-top: 6rem;
}

.i-left{
    display: flex;
    position: relative;
    flex: 1;
    gap: 2rem;
    flex-direction: column;
}

.i-name>:nth-child(1){
    color: var(--black);
    font-weight: bold;
    font-size: 2.5rem;
}

.i-name>:nth-child(3){
    color: var(--black);
    font-weight: 100;
    font-size: 14px;
    margin-top: 10px;
}

.i-button{
    width: 6rem;
    height: 1.5rem;
}

.i-icons{
    display: flex;
    gap: 0rem;
    margin-top: 3rem;
}

.i-icons>*{
    transform: scale(0.5);
}

.i-icons>*:hover{
    cursor: pointer;
}

.i-name>:nth-child(2){
    color: var(--orange);
    font-weight: bold;
    font-size: 3rem;
}

.i-name{
    display: flex;
    flex-direction: column;
}

.i-right{
    flex: 1;
    position: relative;
}

.i-right>*{
    position: absolute;
    z-index: 1;
}

.i-right>:nth-child(1){
    transform: scale(0.69);
    left: -15%;
    top: -9%;
}

.i-right>:nth-child(2){
    top: -4.6rem;
    transform: scale(0.67);
    left: -3rem;
}

.i-right>:nth-child(3){
    transform: scale(1.4);
    left: 28%;
}

.blur{
    position: absolute;
    width: 22rem;
    height: 14rem;
    border-radius: 50%;
    background: #edd0ff;
    filter: blur(72px);
    z-index: -9;
    top: -18%;
    left: 56%;
}

@media  screen and (max-width: 480px){
    .intro{
        flex-direction: column;
        height: 60rem;
        gap: 0rem;
        margin: 0.2rem 2rem 0.2rem 0.2rem;
    }
    .i-icons{
        margin-top: 1rem;
    }
    .i-right{
        transform: scale(0.8);
        left: -3rem;
        margin-top: 2rem;
    }
    .i-right .blur{
        display: none;
    }
    .floatingDiv:nth-of-type(1){
        top: 6rem!important;
    }
}