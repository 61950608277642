@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');

.n-wrapper{
    height: 10vh;
    display: flex;
    justify-content: space-between;
}

.n-left{
    flex: 1;
    align-items: center;
    display: flex;
    gap: 2rem;
}

.n-name{
    font-size: 1.3rem;
    font-weight: bold;
}

.n-right{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-weight: 400;
}

.n-list{
    flex: 10;
    font-family: 'Michroma', sans-serif;
    font-size: 16px;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.0);
}

.n-list>ul{
    display: flex;
    gap: 2rem;
    margin-right: 4rem;
    cursor: pointer;
}

.n-list>ul>li:hover{
    cursor: pointer;
    color: var(--orange);
}

.n-button{
    flex: 2;
}

@media  screen and (max-width: 480px){
    .n-list{
        display: none;
    }
    .n-button{
        display: none;
    }
}

