.floatingdiv{
    justify-content: space-around;
    display: flex;
    background: white;
    box-shadow: var(--boxShadow);
    border-radius: 17px;
    align-items: center;
    padding: 0px 28px 0px 0px;
    height: 4.5rem;
}

.floatingdiv>img{
    transform: scale(0.4);
}

.floatingdiv>span{
    font-size: 16px;
    color: black;
}

.i-right>:nth-child(4){
    transform: scale(0.6);
    top: -19%;
    left: -24%;
    border-radius: 50%;
    padding: 0;
}