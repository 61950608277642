.card{
    width: 10rem;
    height: 15rem;
    position: absolute;
    display: flex;
    flex-direction: column;    
    align-items: center;
    text-align: center;
    background: rgba(255, 255, 255, 0.25);
    border: 7px solid var(--orangeCard);
    box-shadow: var(--boxShadow);
    border-radius: 20px;
    padding: 0px 26px 2rem 26px;
    gap: 1rem;
}

.card span:nth-of-type(2){
    color: var(--gray);
    font-size: 16px;
}

.card > img{
    transform: scale(0.6);
    margin-bottom: -2rem;
}

.c-button{
    background: #ffffff;
    box-shadow: 0px 19px 60px rgba(0, 0, 0, 0.08);
    border-radius: 7px;
    font-size: 16px;
    border: none;
    padding: 2px 8px 2px 8px;
    color: #5290fd;
}

@media  screen and (max-width: 480px){
    .cards{
        width: auto;
    }
}