@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');

.footer{
    display: flex;
    align-items: center;
    margin: -0.5rem -3.5rem;
    position: relative;
    margin-top: -7rem;
}

.f-content{
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 4rem;
    gap: 4rem;
    color: white;
}
.f-icons{
    display: flex;
    gap: 2rem;
}
.f-email{
    font-family: 'Michroma', sans-serif;
    font-size: 23px;
}

@media  screen and (max-width: 480px){
    .footer{
        height: 15rem;
        margin-top: -9rem;
    }
    .f-img{
        height: 100%;
    }
    .f-email{
        transform: scale(0.85);
    }
    .f-content{
        gap: 2rem;
    }

}